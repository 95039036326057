@font-face {
    font-family: 'HK Grotesk SemiBold';
    src: local('HK Grotesk'), url(../../Fonts/HKGrotesk-SemiBold.woff) format('woff');
}

@font-face {
    font-family: 'HK Grotesk Bold';
    src: local('HK Grotesk'), url(../../Fonts/HKGrotesk-Bold.woff) format('woff');
}

@font-face {
    font-family: 'HK Grotesk';
    src: local('HK Grotesk'), url(../../Fonts/HKGrotesk-Regular.woff) format('woff');
}

@font-face {
    font-family: 'HK Grotesk Bold';
    src: local('HK Grotesk'), url(../../Fonts/HKGrotesk-Black.woff) format('woff');
}

.iB-page {
    background-color: #f2f2f2c2;
    margin-left: auto;
    margin-right: auto;
    padding: 4.5%;
    margin-top: calc(65px + 8vh);
    filter: drop-shadow(0 2mm 2mm #3b3b3b3f);
}

.content {
    margin-left: 5vw;
    margin-right: 5vw;
    width: 90vw;

}
/*Headings*/

#ib-heading {
    color: #39536C;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 50%;
    text-align: center;
    margin-bottom: 1%;
}

#ib-subtitle {
    color: #39536C;
    font-family: 'HK Grotesk SemiBold', sans-serif;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 0;
    margin-bottom: 4%;
    max-width: 90%;
    font-size: 18px;
}

.ib-project-subheadings {
    font-family: 'HK Grotesk Bold', sans-serif;
    font-size: 22px;
    color: #39536C;
    margin-top: 1.5%;
    margin-bottom: 0;
}

.ib-h3 {
    font-family: 'HK Grotesk SemiBold', sans-serif;
    font-size: 36px;
    color: #C4DFEC;
    padding-left: 1.5%;
    padding-right: 1.5%;
    padding-top: 3%;
    padding-bottom: 3%;
    max-width: 70vw;
    margin-left: auto;
    margin-right: auto;
}

.iBh4 {
    font-family: 'HK Grotesk Bold', sans-serif;
    font-size: 18px;
    color: #39536C;
    margin-bottom: 0;
}

.ib-h6 {
    font-family: 'HK Grotesk Bold', sans-serif;
    font-size: 18px;
    color: #39536C;
}

.test-patai-subheadings {
    margin-bottom: 1.5%;
    font-family: 'HK Grotesk Bold', sans-serif;
    font-size: 16px;
    color: #39536C;
}

.IB-testing-subheadings {
    text-align: center;
    margin-bottom: 2.5%;
    margin-top: 4%;
    font-family: 'HK Grotesk Bold', sans-serif;
    font-size: 22px;
    color: #39536C;
}

.project-title-main {
    font-family: 'HK Grotesk Black', sans-serif;
    font-size: 35px;
    color: #39536C;
}

.project-subtitles {
    font-family: 'HK Grotesk Bold', sans-serif;
    font-size: 25px;
    margin-top: 1.5%;
    margin-bottom: 0;
    color: #39536C;
}

.Concept1-Heading {
    font-family: 'HK Grotesk Black', sans-serif;
    font-size: 18px;
    color: #39536C;
    text-align: right;
    max-width:4.5em;
    background-color: #C4DFEC;
    padding-right: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 15%;
}

.Concept2-Heading {
    font-family: 'HK Grotesk Bold', sans-serif;
    font-size: 18px;
    color: #39536C;

}

.Concept3-Heading {
    font-family: 'HK Grotesk Bold', sans-serif;
    font-size: 25px;
    color: #39536C;
    transform: rotate(-90deg);
    width: 100px;
    height: 80px;
    text-align: right;
}

#c3-heading-flex {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-left: 30px;
    max-width: 10vw;
}

.IB-testing-headings{
    font-family: 'HK Grotesk Bold', sans-serif;
    font-size: 18px;
    color: #39536C;
}

#session-plan-heading {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-left: 3vw;
}

#results-section {
    margin-top: 2%;
}

#list-subheading {
    font-family: 'HK Grotesk SemiBold', sans-serif;
    font-size: 18px;
    color: #39536C;
    margin-bottom: 0.5%;
}

/* */

.flex-1 {
    display: flex;
}

.iB-hero-vid {
    width: 60%;
}

.IB-Overview {
    margin-bottom: 3%;
}

.IB-Intro {
    display: flex;
    align-items: flex-end;
    width: 100%;
}

.IB-Concept1 {
    border-top: #C4DFEC solid 3px;
    border-right: #C4DFEC solid 3px;
    border-bottom: #C4DFEC solid 3px;
    padding-inline: 40px;
    max-width: 45vw;
    display: flex;
}

.IB-Concept2 {
    width: 100vw;
    height: 80vh;
    padding-left:3%;
    padding-right: 3%;
    padding-bottom: 3%
}

.IB-Concept3 {
    width: 90vw;
    background-color:#C4DFEC;
    padding: 5px;
    margin-top: 1.5%;
    margin-bottom: 1.5%;
}

.ib-c2-bg {
    background-color: #C4DFEC;
    padding-left:5%;
    padding-right: 5%;
    padding-bottom: 3%;
    display: flex;
    width:42vw;
    margin-bottom: 3%;
}

.iB-concept-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#iB-c3-flex {
    display: flex;
    padding: 2%;
    justify-content: space-between;
}

#iB-concept-dvpt {
    margin-left: 3%;
}

.iB-reqs-container {
    display: flex;
    justify-content: space-between;
    border: 3px solid #C4DFEC;
    margin-bottom: 2%;
}

/*Paragraphs*/
.ov-paragraph{
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 2em;
    font-family: 'HK Grotesk SemiBold', sans-serif;
    font-size: 22px;
    color: #3B3B3B;
}

.body-content {
    font-family: 'HK Grotesk SemiBold', sans-serif;
    font-size: 18px;
    color: #3B3B3B;
    margin-top: 0;
}

.body-content-bold{
    font-family: 'HK Grotesk Bold', sans-serif;
    font-size: 22px;
    color: #3B3B3B;
    margin-top: 0;
    margin-bottom: 1.5%;
}

.feature-text-container {
    width: 100%;
    min-height: 38vh;
    background-color: #39536C;
    margin-bottom: 1.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.prototype {
    margin-left: 5%;
    width: 80vw;
}

.feature-text {
    font-family: 'HK Grotesk Black', sans-serif;
    font-size: 35px;
    color: #C4DFEC;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    margin-bottom:0;
    width: 70%;
    padding:5%;
}

.ib-c1-text {
    width: 30%;
    margin-right: 5%;
}

.Concept-Paragraph {
    margin-left: 2%;
    margin-right: 2%;
}


#iBhomeLabel {
    font-family: 'HK Grotesk SemiBold', sans-serif;
    font-size: 18px;
    color: #3B3B3B;
    width: 40vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.User-Testing {
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
}

.IB-testing-reqs {
    width:20vw;
    padding-left: 3%;
    padding-right: 1.5%;
}

/*images*/
#iBoffice {
    width: 40vw;
    margin-top: 1%;
    margin-bottom: 3%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

#iB-brainstorm{
    width: 50vw;
    border: 3px solid #C4DFEC;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1%;
    margin-bottom: 1%;
}

.iB-c1img {
    width: 100%;
    margin-right: 0;
    margin-top: 7%;
    margin-bottom: 7%;
}

.iB-c2img {
    width: 11vw;
    margin-bottom:3%;
    padding-top:5%;
    margin-left: 1%;
    margin-right: 1%;
}

#ib-c2-imgs-container {
    width: 100%;
}

#iB-chosen-img {
    width:70vw;
}

#iB-it1 {
    max-width: 45vw;
}

#iBhome {
    width: 70vw;
    display: block;
    margin-right: auto;
    margin-left: auto;
    box-shadow: 1px 25px 50px rgba(0, 0, 0, 0.2);
}

/* IMAGES */
.ib-hero-imgs {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 3%;
    width: 100%;
}

.ib-hero-img {
    max-width: 49%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    filter: drop-shadow(0 1mm 2mm #e2e2e2);
}

#ib-homepage-hero {
    filter: drop-shadow(0 2mm 2mm #e2e2e2);
}

.concept {
    max-width: 100%;
}

#chosen-concept {
    max-width: 99%;
    margin-top: 1.5%;
    /* background-color:#39536C; */
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 1%;
    padding-right: 1%;
    filter: drop-shadow(0 0mm 2mm #bdbdbd);
    margin-left: auto;
    margin-right: auto;
}

.testDetailIcons {
    width: 125px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

#results-graphic {
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 1%;
    border: 2px solid #f2f2f2;
    margin-bottom: 3%;
    margin-top: 0;
    background-color: #ffffff;
}

/* TEXT */
.nav-text-items {
    font-family: 'HK Grotesk SemiBold', sans-serif;
    font-size: 18px;
    color: #3B3B3B;
    text-decoration: none;
    padding: 10px;
}

.test-plan-description {
    margin-left: 10%;
    font-size: 18px;
}

#test-plan-p {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3%;
}

#page-ib p {
    margin-top: 1%;
}

/* OTHER STYLING */

.iB-written-content {
    width: 82%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
}

.iB-discovery {
    background-color: #f6f6f6;
    margin-top: 0;
    padding-top: 2%;
    padding-bottom: 2%;
}

.ib-development {
    background-color: #e6e6e6;
    margin-top: 0;
    padding-top: 2%;
    padding-bottom: 2%;
}

.ib-testing {
    background-color: #ffffff;
    margin-top: 0;
    padding-top: 2%;
    padding-bottom: 2%;
}

.ib-results {
    background-color: #e6e6e6;
    margin-top: 0;
    padding-top: 2%;
    padding-bottom: 2%;
}

.ib-future {
    background-color: #f2f2f2;
    margin-top: 0;
    padding-top: 2%;
    padding-bottom: 2%;
}

.it-vid {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

#visualisation-its {
    display: flex;
    justify-content: space-between;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.ib-its-wrapper {
    max-width: 49.5%;
    margin-bottom: 3%;
    filter: drop-shadow(0 2mm 2mm #bebebe);
}

.ib-video-section {
    width: 70%;
    background-color: #c4dfec62;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.video-section-2 {
    width: 80%;
    background-color:#3c3c3c;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    filter: drop-shadow(0 0mm 2mm #bdbdbd);
    border-radius: 5px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

#hmw {
    max-width: 80%;
    background-color: #39536C;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-family: 'HK Grotesk Bold', sans-serif;
    border-radius: 10px;
}

.iB-reqs-container {
    max-width: 80vw;
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    border: 3px solid #f2f2f2;
    background-color: #f2f2f2;;
}

.IB-test-design {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-left: auto;
    margin-right: auto;

}

.Test-Details {
    padding: 1.5%;
    max-width: 20vw;
    margin-bottom: 3%;
    justify-self: center;

}

.ib-feature-quote {
    max-width: 75%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: #f2f2f2;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    padding-left: 3%;
    padding-right: 3%;
}

.concepts {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.scrapped-concepts {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

#test-plan {
    border: 3px solid #39536C;
    border-radius: 3px;
}

.box {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    margin-bottom: 3%;
}

.ib-hero-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

#page-ib {
    width: 80vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 768px) {
    .iB-reqs-container {
        flex-direction: column;
    }

    .IB-testing-reqs {
        width: 90%;
        padding-left: 3%;
        padding-right: 1.5%;
    }
}

@media only screen and (max-width: 1060px) {

    #visualisation-its {
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        width: 100%;
        margin-bottom: 1%;
        padding-top: 2%;
    }

    .video-section-2 {
        border-radius: 0;
        padding-top: 1%;
        padding-bottom: 1%;
    }

    .ib-its-wrapper {
        max-width: 90%;
        margin-bottom: 3%;
        filter: drop-shadow(0 2mm 2mm #bebebe);
    }

    .ib-hero-imgs {
        flex-direction: column;
    }

    .ib-hero-img {
        max-width: 100%;
        margin-bottom: 1.5%;
    }
}


@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: 'Fraunces';
  font-display: swap;
  font-weight: 100 500 600 700 900;
  src: url(/static/media/Fraunces--latin_basic.3a771f2e.woff2) format('woff2');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

@font-face {
  font-family: 'Fraunces';
  font-display: swap;
  font-weight: 100 700 900;
  src: url(/static/media/Fraunces--latin_ext.c89917b1.woff2) format('woff2');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

.App {
  margin-top:0;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  padding-bottom: 5%;
  position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.dropdownContent {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in;
  margin-bottom: 5%;
}

.dropdownContainer:hover > .dropdownContent {
  max-height: 500px;
}

.swiper-container {
  background-color: #FFFFFF;
}

.popup-content {
  background:#FFFFFF !important;
}

.visibleContainer {
  max-height:300px;
}

/* typography */

body {
  background-color: #F2E5D9;
  font-size: 16px;
}

h1 {
  font-family: "Fraunces", serif;
  font-optical-sizing: auto;
  font-weight: 680;
  font-style: normal;
  font-variation-settings:
    "SOFT" 100,
    "WONK" 0.5;
  color: #5C5347;
  font-size: clamp(2rem, 3.25vw, 9rem);
  margin-top: 0;
  margin-bottom: 3%;
}

h2 {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  font-size: clamp(1.25rem, 1.15vw, 2.25rem);
  color: #382F2F;
  text-decoration: none;
  margin-bottom: 2.5%;
}

h2.contact {
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  font-size: clamp(0.8rem, 1.25vw, 1.5rem);
  color: #382F2F;
  text-transform: uppercase;
}

p {
  font-family: "Manrope", sans-serif;
  font-weight: 500;
  font-size: clamp(1rem, 1vw, 1.75rem);
  color: #382F2F;
}

.p-feature {
  font-family: "Fraunces", serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-variation-settings:
    "SOFT" 100,
    "WONK" 0.5;
  color: #5C5347;
  font-size: clamp(1.75rem, 3.5vw, 6.5rem);
}

.p-medium {
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  font-size: clamp(1rem, 1.25vw, 1.5rem);
  color: #382F2F;
  text-decoration: none;
  margin-bottom: 5%;
}

.p-attribution {
  font-family: "Manrope", sans-serif;
    font-weight: 400;
    font-size: clamp(0.8rem, 0.6vw, 1.25rem);
    color: #382F2F;
    text-decoration: none;
}

a {
  -webkit-text-decoration:underline 1px solid;
          text-decoration:underline 1px solid;
  text-underline-offset: 5px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: clamp(0.9rem, 1vw, 2rem);
  color: #382F2F;
}

.large-link:has(button) {
  text-decoration: none;
}

a:hover {
  transform: scale(1.1);
  transition: all 0.33s ease-in-out;
  -webkit-text-decoration-style: wavy;
          text-decoration-style: wavy;
}

.large-link {
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: clamp(1.25rem, 1vw, 2rem);
  color: #382F2F;
  text-decoration: underline;
  text-underline-offset: 15px;
}

button {
  font-family: "Manrope", sans-serif;
    font-weight: 600;
    font-size: clamp(1rem, 1vw, 1.75rem);
    color: #382F2F;
    text-decoration: none;
    padding-top:0.5rem;
    padding-bottom:0.5rem;
    /* padding-left: 3rem;
    padding-right: 3rem; */
    border: 1.5px solid #382F2F;
    border-radius: 45px;
    box-shadow: 2px 2px 4px #5c534766;
    background-color: #F2EFEB;
    text-align: center;
}

ul {
	position: relative;
	list-style-type: circle;
	margin-left: 0;
	padding-left: 0.5rem;
}

li {
  font-family: "Manrope", sans-serif;
  font-weight: 500;
  font-size: clamp(1rem, 1vw, 1.75rem);
  color: #382F2F;
  text-decoration: none;  
  padding-left: 10px;
}

span {
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  font-size: clamp(1rem, 1vw, 1.75rem);
  color: #382F2F;
  text-decoration: none;
}

span.project-label-large {
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  font-size: clamp(1rem, 1.2vw, 2.25rem);
  color: #382F2F;
  text-decoration: none;
}

label {
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  font-size: clamp(1rem, 1vw, 1.5rem);
  color: #382F2F;
}

textarea {
  font-family: "Manrope", sans-serif;
  font-weight: 500;
  font-size: clamp(1rem, 1vw, 1.5rem);
}

input {
  font-family: "Manrope", sans-serif;
  font-weight: 500;
  font-size: clamp(1rem, 1vw, 1.5rem);
  color: #382F2F;
}

/* content blocks */

.full-width-content-block {
  width: 100%;
}

.text-block-third {
  width: calc(100% / 3);
  padding-right: 3%;
}

.text-block-half {
  width: calc(100% / 2);
  padding-right: 3%;
}

.half-width-content-block {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 3%;
}

.video-section {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 80vw;
  margin-bottom: 3%;
}

/* media queries small*/

@media only screen and (max-width: 480px) {
  .half-width-content-block {
    flex-direction: column;
    grid-row-gap: 20px;
    row-gap: 20px;
  }
} 
.intro {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

#intro-text-container {
    width: 66%;
    height: 30%;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    margin-top: auto;
    margin-bottom: auto;
}

.large-link:hover {
    text-decoration:underline;
    -webkit-text-decoration-style: wavy;
            text-decoration-style: wavy;
    transition: all .2s ease-in-out;
}

#home-wave {
    width: 100%;
    rotate: 180deg;
    position: fixed;
    left: 0;
    bottom: 0;
}

#home-wave .shape-fill {
    fill: #5C5347;
}

/* #wave1 {
    height: 10vh;
} */

/* media queries small */

@media only screen and (max-width: 480px) {
    #intro-text-container {
        width: 100%;
    }

    #wave1 {
        height: 10vh;
        width: 100%;
    }
}


.contact-form {
    display: flex;
    flex-direction: column;
    width: calc(100vw / 2);
    /* margin-top: 40px; */
    margin-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0;
    padding-top: 5vh;
    background-color: rgba(255, 255, 255, 0.53);
    border-radius: 10px;
    margin-left: 5%;
}

#contact-cta {
    color: #382F2F;
    margin-right: 20%;
    margin-bottom: 0;
}

input {
    padding: 10px;
    margin-bottom: 40px;
    margin-top: 15px;
    border-radius: 10px;
    border: 1.5px solid #382f2f74;
}

label {
    display: flex;
    flex-direction: column;
}

.fields {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
}

textarea {
    margin-bottom: 40px;
    margin-top: 15px;
    padding: 10px;
    border-radius: 10px;
    border: 1.5px solid #382f2f74;
    height: 300px;
}

.contact-heading {
    margin-bottom: 5%;
}

.button-wide {
    margin-bottom: 20%;
    display: block;
    float: right;
    text-decoration: none;
    width: calc(100%/ 3);
}

#email-wave {
    width: 100%;
    rotate: 180deg;
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 10vh;
}

#email-wave .shape-fill {
    fill: #5C5347;
}

#returntowork {
    margin-top: 7.5%;
}

/* media queries */

@media only screen and (max-width: 779px) {
    .contact-form {
        width: 80vw;
        margin-left: auto;
        margin-right: auto;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        padding-top: 2.5vh;
    }

    #success-msg {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    #returntowork {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        margin-top: 15%;
    }

    .large-link.c-success {
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
}

/* media queries xl */
@media only screen and (min-width: 3500px) {

    label {
        grid-row-gap: 10px;
        row-gap: 10px;
    }

    input {
        margin-bottom: 65px;
    }

    textarea {
        margin-bottom: 65px;
    }
}
.project-page {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
}

.project-heading-container {
   display: flex;
   justify-content: flex-start; 
   margin-bottom: 0;
}

.long-project-title {
    margin-top: 2.5%;
}

.type-of-work {
    font-weight: 800;
    color: #5C5347;
    margin-left: 0;
    margin-right: 0;
    margin-top: 5%;
    margin-bottom: 5%;
}

.external-link {
    font-family: "Manrope", sans-serif;
    font-weight: 800;
    color: #382F2F;
    margin-bottom: 15%;
    text-decoration:underline;
    text-underline-offset: 5px;
}

.hero-two-thirds {
    margin-top: 3%;
    width: 70%;
}

.body-copy {
    width: calc(80vw / 3 * 2);
    float: right;
    margin-top: 0;
    margin-bottom: 3%;
}

.full-width-test {
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    margin-bottom: 3%;
}

#half-width-test {
    width: 48.5%;
}

.hero-image-w {
    width: 80vw;
    height: 82vh;
}

.main-project-image {
    max-width: 60%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5vh;
    margin-bottom: 5vh;
}

.final-images {
    width: 29vw;
    height: 35vh;
    margin-bottom: 3%;
}

.finalImages {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;
}

.photoSeriesImgs {
    width: 13vw;
    height: 7vh;
}

.photoSeries {
    max-width: 60%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 0;
    -webkit-column-gap: 0;
            column-gap: 0;
}

.infographic {
    max-width: 100vw;
    height: 200vh;
}

.content{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.process {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.processVid {
    max-width: 100%;
}

.processVids {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
}

.video-section {
    width: 100%;
    border-radius: 10px;
}

.vid {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    border-radius: 20px;
    -webkit-filter: drop-shadow(0 2mm 2mm #515d6346);
            filter: drop-shadow(0 2mm 2mm #515d6346);
}

.center-img {
    display: flex;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 5%;
}

.hint-flows {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.hint-dp {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-top: 0;
    align-content: center;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-bottom: 5%;
}

.hint-sketches {
    max-height: 45vh;
}

.hint-sketches2 {
    max-height: 60vh;
    justify-self: starts;
}

#displace1 {
    background-image: url(/static/media/dis1.cb2ffbff.jpg);
    background-size: contain;
    margin-bottom: 3vh;
    background-repeat: no-repeat;
}
#displace2 {
    background-image: url(/static/media/dis2.f463a85f.jpg);
    background-size: contain;
    margin-bottom: 3vh;
    background-repeat: no-repeat;
}
#displace3 {
    background-image: url(/static/media/dis3.4feda4fb.jpg);
    background-size: contain;
    margin-bottom: 3vh;
    background-repeat: no-repeat;
}
#displace4 {
    background-image: url(/static/media/dis4.acc1fc14.jpg);
    background-size: contain;
    margin-bottom: 3vh;
    background-repeat: no-repeat;
}
#displace5 {
    background-image: url(/static/media/dis5.e5bfa666.jpg);
    background-size: contain;
    margin-bottom: 3vh;
    background-repeat: no-repeat;
}

#displace6 {
    background-image: url(/static/media/dis6.389aea26.png);
    background-size: contain;
    background-repeat: no-repeat;
}

.disGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-self: center;
    align-self: center;
}

#df {
    background-image: url(/static/media/dreamy-finder.361b8dc2.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center left;
}

#bus {
    background-image: url(/static/media/bus-hero.9d1279a5.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

/* media queries small */

@media only screen and (max-width: 480px) {
    .project-heading-container {
        flex-direction: column;
        margin-bottom: 0;
    }

    .project-heading-container :nth-child(2) {
        order: -1; 
    }

    #half-width-test {
        width: 100%;
    }

    .full-width-test {
        margin-bottom: 20px;
    }

    .hero-two-thirds {
        width: 100%;
    }

    .long-project-title {
        margin-top: 2.5%;
    }

    .body-copy {
        width: 100%;
    }
}
.contactFooter {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    margin-top: 0;
    margin-bottom: 5%;
    padding-bottom: 50px;
}

.footer-hr {
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
    border: 0;
    height: 0;
    border: 1px solid #5C5347;
}

.contact-blurb {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.5%;
}

#contact-textblock {
    width: calc(100% / 3 * 2);
    padding-right: 5%;
}

.contact {
    text-transform: uppercase;
    margin-bottom: 10px;
}

#footer-text {
    margin-top: 10px;
    margin-bottom: 10px;
}

.button-reg {
    padding-left: 3vw;
    padding-right: 3vw;
}

button.button-reg:hover {
    transform: scale(1.05);
    background-color: #5C5347;
    color: white;
    transition: all .2s ease-in-out;
    font-weight: 600;
}

.contact-links {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    grid-row-gap: 5%;
    row-gap: 5%;
    margin-top: 2.5%;
    margin-right: 2.5%;
}

.contactLink {
    padding-right: 2.5%;
    -webkit-text-decoration: underline 1px solid;
            text-decoration: underline 1px solid;
    margin-bottom: 15%;
    text-underline-offset: 5px;
}

.contactLink:hover {
    -webkit-text-decoration: underline 2px;
            text-decoration: underline 2px;
    -webkit-text-decoration-style: wavy;
            text-decoration-style: wavy;
    text-underline-offset: 5px;
    transition: all .2s ease-in-out;
}


/* wave footer svg version */

.wavefooter svg {
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    width: calc(100% + 1.3px);
    height: 10vh;
    transform: rotate(180deg);
}

.wavefooter .shape-fill {
    fill: #5C5347;
}
@font-face {
    font-family: 'HK Grotesk SemiBold';
    src: local('HK Grotesk'), url(/static/media/HKGrotesk-SemiBold.75f2b19b.woff) format('woff');
}

@font-face {
    font-family: 'HK Grotesk Bold';
    src: local('HK Grotesk'), url(/static/media/HKGrotesk-Bold.29f56d9e.woff) format('woff');
}

@font-face {
    font-family: 'HK Grotesk';
    src: local('HK Grotesk'), url(/static/media/HKGrotesk-Regular.a22f9227.woff) format('woff');
}

@font-face {
    font-family: 'HK Grotesk Bold';
    src: local('HK Grotesk'), url(/static/media/HKGrotesk-Black.aebf1a9c.woff) format('woff');
}
.dis-p {
    font-family: franklin-gothic-urw,sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 16px;
    max-width: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    color: #c1c1c1;
}

#displacement-intro {
    font-family: franklin-gothic-urw,sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 26px;
    margin-top: 10%;
    max-width: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    color: #c1c1c1;
}

.dis-page {
    margin-left: auto;
    margin-right: auto;
    margin-top: calc(65px + 10vh);
    margin-bottom: 0;
}

#dis-heading {
    color: #e42a2a;
    max-width: 64%;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
}

.dis-photo-caption {
    max-width: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40%;
    margin-top: 10%;
}

.dis-img {
    max-width: 100%;
}

.dis-caption {
    font-family: franklin-gothic-urw,sans-serif;
    font-weight: 600;
    font-size: 1vw;
    color: #201d28;
    max-width: 100%;
    text-align: end;

}

#dis-last {
    margin-bottom: 10%;
}

#dis-img-all {
    max-width: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
}

.photos-section {
    background-color: #ffffff;
    max-width: 60%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 15%;
    padding-bottom: 7%;
    padding-left: 2%;
    padding-right: 2%;
}

.dis-text {
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: #201d28;
    max-width: 90%;
    padding-top: 5%;
    padding-bottom: 10%;
}

@media only screen and (max-width: 600px) {
    .photos-section {
        max-width: 90%;
    }
    
    #dis-heading {
        max-width: 94%;
    }
}
@font-face {
    font-family: 'HK Grotesk SemiBold';
    src: local('HK Grotesk'), url(/static/media/HKGrotesk-SemiBold.75f2b19b.woff) format('woff');
}

@font-face {
    font-family: 'HK Grotesk Bold';
    src: local('HK Grotesk'), url(/static/media/HKGrotesk-Bold.29f56d9e.woff) format('woff');
}

@font-face {
    font-family: 'HK Grotesk';
    src: local('HK Grotesk'), url(/static/media/HKGrotesk-Regular.a22f9227.woff) format('woff');
}

@font-face {
    font-family: 'HK Grotesk Bold';
    src: local('HK Grotesk'), url(/static/media/HKGrotesk-Black.aebf1a9c.woff) format('woff');
}

.iB-page {
    background-color: #f2f2f2c2;
    margin-left: auto;
    margin-right: auto;
    padding: 4.5%;
    margin-top: calc(65px + 8vh);
    -webkit-filter: drop-shadow(0 2mm 2mm #3b3b3b3f);
            filter: drop-shadow(0 2mm 2mm #3b3b3b3f);
}

.content {
    margin-left: 5vw;
    margin-right: 5vw;
    width: 90vw;

}
/*Headings*/

#ib-heading {
    color: #39536C;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 50%;
    text-align: center;
    margin-bottom: 1%;
}

#ib-subtitle {
    color: #39536C;
    font-family: 'HK Grotesk SemiBold', sans-serif;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 0;
    margin-bottom: 4%;
    max-width: 90%;
    font-size: 18px;
}

.ib-project-subheadings {
    font-family: 'HK Grotesk Bold', sans-serif;
    font-size: 22px;
    color: #39536C;
    margin-top: 1.5%;
    margin-bottom: 0;
}

.ib-h3 {
    font-family: 'HK Grotesk SemiBold', sans-serif;
    font-size: 36px;
    color: #C4DFEC;
    padding-left: 1.5%;
    padding-right: 1.5%;
    padding-top: 3%;
    padding-bottom: 3%;
    max-width: 70vw;
    margin-left: auto;
    margin-right: auto;
}

.iBh4 {
    font-family: 'HK Grotesk Bold', sans-serif;
    font-size: 18px;
    color: #39536C;
    margin-bottom: 0;
}

.ib-h6 {
    font-family: 'HK Grotesk Bold', sans-serif;
    font-size: 18px;
    color: #39536C;
}

.test-patai-subheadings {
    margin-bottom: 1.5%;
    font-family: 'HK Grotesk Bold', sans-serif;
    font-size: 16px;
    color: #39536C;
}

.IB-testing-subheadings {
    text-align: center;
    margin-bottom: 2.5%;
    margin-top: 4%;
    font-family: 'HK Grotesk Bold', sans-serif;
    font-size: 22px;
    color: #39536C;
}

.project-title-main {
    font-family: 'HK Grotesk Black', sans-serif;
    font-size: 35px;
    color: #39536C;
}

.project-subtitles {
    font-family: 'HK Grotesk Bold', sans-serif;
    font-size: 25px;
    margin-top: 1.5%;
    margin-bottom: 0;
    color: #39536C;
}

.Concept1-Heading {
    font-family: 'HK Grotesk Black', sans-serif;
    font-size: 18px;
    color: #39536C;
    text-align: right;
    max-width:4.5em;
    background-color: #C4DFEC;
    padding-right: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 15%;
}

.Concept2-Heading {
    font-family: 'HK Grotesk Bold', sans-serif;
    font-size: 18px;
    color: #39536C;

}

.Concept3-Heading {
    font-family: 'HK Grotesk Bold', sans-serif;
    font-size: 25px;
    color: #39536C;
    transform: rotate(-90deg);
    width: 100px;
    height: 80px;
    text-align: right;
}

#c3-heading-flex {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-left: 30px;
    max-width: 10vw;
}

.IB-testing-headings{
    font-family: 'HK Grotesk Bold', sans-serif;
    font-size: 18px;
    color: #39536C;
}

#session-plan-heading {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-left: 3vw;
}

#results-section {
    margin-top: 2%;
}

#list-subheading {
    font-family: 'HK Grotesk SemiBold', sans-serif;
    font-size: 18px;
    color: #39536C;
    margin-bottom: 0.5%;
}

/* */

.flex-1 {
    display: flex;
}

.iB-hero-vid {
    width: 60%;
}

.IB-Overview {
    margin-bottom: 3%;
}

.IB-Intro {
    display: flex;
    align-items: flex-end;
    width: 100%;
}

.IB-Concept1 {
    border-top: #C4DFEC solid 3px;
    border-right: #C4DFEC solid 3px;
    border-bottom: #C4DFEC solid 3px;
    padding-inline: 40px;
    max-width: 45vw;
    display: flex;
}

.IB-Concept2 {
    width: 100vw;
    height: 80vh;
    padding-left:3%;
    padding-right: 3%;
    padding-bottom: 3%
}

.IB-Concept3 {
    width: 90vw;
    background-color:#C4DFEC;
    padding: 5px;
    margin-top: 1.5%;
    margin-bottom: 1.5%;
}

.ib-c2-bg {
    background-color: #C4DFEC;
    padding-left:5%;
    padding-right: 5%;
    padding-bottom: 3%;
    display: flex;
    width:42vw;
    margin-bottom: 3%;
}

.iB-concept-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#iB-c3-flex {
    display: flex;
    padding: 2%;
    justify-content: space-between;
}

#iB-concept-dvpt {
    margin-left: 3%;
}

.iB-reqs-container {
    display: flex;
    justify-content: space-between;
    border: 3px solid #C4DFEC;
    margin-bottom: 2%;
}

/*Paragraphs*/
.ov-paragraph{
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 2em;
    font-family: 'HK Grotesk SemiBold', sans-serif;
    font-size: 22px;
    color: #3B3B3B;
}

.body-content {
    font-family: 'HK Grotesk SemiBold', sans-serif;
    font-size: 18px;
    color: #3B3B3B;
    margin-top: 0;
}

.body-content-bold{
    font-family: 'HK Grotesk Bold', sans-serif;
    font-size: 22px;
    color: #3B3B3B;
    margin-top: 0;
    margin-bottom: 1.5%;
}

.feature-text-container {
    width: 100%;
    min-height: 38vh;
    background-color: #39536C;
    margin-bottom: 1.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.prototype {
    margin-left: 5%;
    width: 80vw;
}

.feature-text {
    font-family: 'HK Grotesk Black', sans-serif;
    font-size: 35px;
    color: #C4DFEC;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    margin-bottom:0;
    width: 70%;
    padding:5%;
}

.ib-c1-text {
    width: 30%;
    margin-right: 5%;
}

.Concept-Paragraph {
    margin-left: 2%;
    margin-right: 2%;
}


#iBhomeLabel {
    font-family: 'HK Grotesk SemiBold', sans-serif;
    font-size: 18px;
    color: #3B3B3B;
    width: 40vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.User-Testing {
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
}

.IB-testing-reqs {
    width:20vw;
    padding-left: 3%;
    padding-right: 1.5%;
}

/*images*/
#iBoffice {
    width: 40vw;
    margin-top: 1%;
    margin-bottom: 3%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

#iB-brainstorm{
    width: 50vw;
    border: 3px solid #C4DFEC;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1%;
    margin-bottom: 1%;
}

.iB-c1img {
    width: 100%;
    margin-right: 0;
    margin-top: 7%;
    margin-bottom: 7%;
}

.iB-c2img {
    width: 11vw;
    margin-bottom:3%;
    padding-top:5%;
    margin-left: 1%;
    margin-right: 1%;
}

#ib-c2-imgs-container {
    width: 100%;
}

#iB-chosen-img {
    width:70vw;
}

#iB-it1 {
    max-width: 45vw;
}

#iBhome {
    width: 70vw;
    display: block;
    margin-right: auto;
    margin-left: auto;
    box-shadow: 1px 25px 50px rgba(0, 0, 0, 0.2);
}

/* IMAGES */
.ib-hero-imgs {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 3%;
    width: 100%;
}

.ib-hero-img {
    max-width: 49%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    -webkit-filter: drop-shadow(0 1mm 2mm #e2e2e2);
            filter: drop-shadow(0 1mm 2mm #e2e2e2);
}

#ib-homepage-hero {
    -webkit-filter: drop-shadow(0 2mm 2mm #e2e2e2);
            filter: drop-shadow(0 2mm 2mm #e2e2e2);
}

.concept {
    max-width: 100%;
}

#chosen-concept {
    max-width: 99%;
    margin-top: 1.5%;
    /* background-color:#39536C; */
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 1%;
    padding-right: 1%;
    -webkit-filter: drop-shadow(0 0mm 2mm #bdbdbd);
            filter: drop-shadow(0 0mm 2mm #bdbdbd);
    margin-left: auto;
    margin-right: auto;
}

.testDetailIcons {
    width: 125px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

#results-graphic {
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 1%;
    border: 2px solid #f2f2f2;
    margin-bottom: 3%;
    margin-top: 0;
    background-color: #ffffff;
}

/* TEXT */
.nav-text-items {
    font-family: 'HK Grotesk SemiBold', sans-serif;
    font-size: 18px;
    color: #3B3B3B;
    text-decoration: none;
    padding: 10px;
}

.test-plan-description {
    margin-left: 10%;
    font-size: 18px;
}

#test-plan-p {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3%;
}

#page-ib p {
    margin-top: 1%;
}

/* OTHER STYLING */

.iB-written-content {
    width: 82%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
}

.iB-discovery {
    background-color: #f6f6f6;
    margin-top: 0;
    padding-top: 2%;
    padding-bottom: 2%;
}

.ib-development {
    background-color: #e6e6e6;
    margin-top: 0;
    padding-top: 2%;
    padding-bottom: 2%;
}

.ib-testing {
    background-color: #ffffff;
    margin-top: 0;
    padding-top: 2%;
    padding-bottom: 2%;
}

.ib-results {
    background-color: #e6e6e6;
    margin-top: 0;
    padding-top: 2%;
    padding-bottom: 2%;
}

.ib-future {
    background-color: #f2f2f2;
    margin-top: 0;
    padding-top: 2%;
    padding-bottom: 2%;
}

.it-vid {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

#visualisation-its {
    display: flex;
    justify-content: space-between;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.ib-its-wrapper {
    max-width: 49.5%;
    margin-bottom: 3%;
    -webkit-filter: drop-shadow(0 2mm 2mm #bebebe);
            filter: drop-shadow(0 2mm 2mm #bebebe);
}

.ib-video-section {
    width: 70%;
    background-color: #c4dfec62;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.video-section-2 {
    width: 80%;
    background-color:#3c3c3c;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    -webkit-filter: drop-shadow(0 0mm 2mm #bdbdbd);
            filter: drop-shadow(0 0mm 2mm #bdbdbd);
    border-radius: 5px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

#hmw {
    max-width: 80%;
    background-color: #39536C;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-family: 'HK Grotesk Bold', sans-serif;
    border-radius: 10px;
}

.iB-reqs-container {
    max-width: 80vw;
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    border: 3px solid #f2f2f2;
    background-color: #f2f2f2;;
}

.IB-test-design {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-left: auto;
    margin-right: auto;

}

.Test-Details {
    padding: 1.5%;
    max-width: 20vw;
    margin-bottom: 3%;
    justify-self: center;

}

.ib-feature-quote {
    max-width: 75%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: #f2f2f2;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    padding-left: 3%;
    padding-right: 3%;
}

.concepts {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.scrapped-concepts {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

#test-plan {
    border: 3px solid #39536C;
    border-radius: 3px;
}

.box {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    margin-bottom: 3%;
}

.ib-hero-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

#page-ib {
    width: 80vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 768px) {
    .iB-reqs-container {
        flex-direction: column;
    }

    .IB-testing-reqs {
        width: 90%;
        padding-left: 3%;
        padding-right: 1.5%;
    }
}

@media only screen and (max-width: 1060px) {

    #visualisation-its {
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        width: 100%;
        margin-bottom: 1%;
        padding-top: 2%;
    }

    .video-section-2 {
        border-radius: 0;
        padding-top: 1%;
        padding-bottom: 1%;
    }

    .ib-its-wrapper {
        max-width: 90%;
        margin-bottom: 3%;
        -webkit-filter: drop-shadow(0 2mm 2mm #bebebe);
                filter: drop-shadow(0 2mm 2mm #bebebe);
    }

    .ib-hero-imgs {
        flex-direction: column;
    }

    .ib-hero-img {
        max-width: 100%;
        margin-bottom: 1.5%;
    }
}


#main-nav {
    margin-top: 2vh;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    height: 5vh;
    margin-bottom: 12vh;
}

#hamburger-nav {
    display: none;
}

#hamburger-list {
    display: none;
}

#nav-contents {
    display: flex;
    justify-content: space-between;
}

.nav-pages {
    display: flex;
    justify-content:right;
    width: 75%;
    align-items: center;
}

#nav-places {
    text-decoration: none;
    padding-left: 5%;
}

#nav-places:hover {
    text-decoration:underline;
    -webkit-text-decoration-style: wavy;
            text-decoration-style: wavy;
    text-decoration-thickness: 2px;
    text-underline-offset: 5px;
}

.nav-logo {
    height: 8vh;
}

/* media query small */

@media only screen and (max-width: 480px) {
    .nav-logo {
        width: 75%;
    }

    #main-nav {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 0;
        padding-top: 15px;
    }

    #nav-contents {
        display: none;
    }

    #hamburger-nav {
        display: flex;
        justify-content: space-between;
        margin-top: 0;
    }

    #hamburger {
        fill: #5C5347;
        margin-top: 10px;
        margin-right: 20px;
    }

    .nav-logo {
        margin-left: 20px;
    }

    #hamburger-list {
        display: flex;
        flex-direction: column;
        grid-row-gap: 5rem;
        row-gap: 5rem;
        width: 100%;
        height: 97vh;
        position: fixed;
        padding-top: 60px;
        z-index: 1;
        padding-left: 10vw;
        margin-top: 6rem;
        background-color: #f7f0e7f0;
            overflow-x: hidden;
            /* transition: all 30s ease-in; */
    }

    #nav-places.nav-link {
        margin-left: 0;
        padding-left: 0;
        font-size: clamp(1.125rem, 6vw, 2.5rem);
        -webkit-text-decoration: 1.5px underline solid;
                text-decoration: 1.5px underline solid;
        text-underline-offset: 10px;
        z-index: 1;
    }
}

/* media query ipad */
/* @media only screen 
    and (min-width: 1024px)
    and (max-height: 1366px)
    {

    .nav-logo {
        max-width: 75%;
        margin-left: 5%;
    }    

    #main-nav {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 0;
        padding-top: 15px;
    }

    #nav-contents {
        display: none;
    }

    #hamburger-nav {
        display: flex;
        justify-content: space-between;
        margin-top: 0;
        max-width: 100%;
        margin-left: 0;
    }

    #hamburger {
        fill: #5C5347;
        margin-top: 10px;
        margin-right: 5%;
        width: 5%;
    }

    #hamburger-list {
        display: flex;
        flex-direction: column;
        row-gap: 5rem;
        width: 100%;
        height: 97vh;
        position: fixed;
        padding-top: 60px;
        z-index: 1;
        padding-left: 10vw;
        margin-top: 6rem;
        background-color: #f7f0e7f0;
        overflow-x: hidden;
    }

    #nav-places.large-link {
        margin-left: 0;
        padding-left: 0;
        text-decoration: 1px underline solid;
        text-underline-offset: 10px;
        z-index: 1;
    }
    
    rect {
        width: 100%;
    }

} */
@font-face {
    font-family: 'HK Grotesk SemiBold';
    src: local('HK Grotesk'), url(/static/media/HKGrotesk-SemiBold.75f2b19b.woff) format('woff');
}

@font-face {
    font-family: 'HK Grotesk Bold';
    src: local('HK Grotesk'), url(/static/media/HKGrotesk-Bold.29f56d9e.woff) format('woff');
}

@font-face {
    font-family: 'HK Grotesk';
    src: local('HK Grotesk'), url(/static/media/HKGrotesk-Regular.a22f9227.woff) format('woff');
}

@font-face {
    font-family: 'HK Grotesk Bold';
    src: local('HK Grotesk'), url(/static/media/HKGrotesk-Black.aebf1a9c.woff) format('woff');
}

#page-cn {
    background-color: #fafdff;
    margin-left: auto;
    margin-right: auto;
    padding: 4.5%;
    margin-top: calc(65px + 8vh);
    -webkit-filter: drop-shadow(0 2mm 2mm #3b3b3b3f);
            filter: drop-shadow(0 2mm 2mm #3b3b3b3f);
}

#cn-hero {
    width:60%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5vw;
    -webkit-filter: drop-shadow(0 2mm 2mm rgba(170, 206, 190, 0.575));
            filter: drop-shadow(0 2mm 2mm rgba(170, 206, 190, 0.575));
}

/*HEADINGS*/

#cn-heading {
    color: #124547;
    max-width: 70%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5%;
}

.cn-h2 {
    font-family: 'HK Grotesk Bold', sans-serif;
    font-size: 22px;
    color: #124547;
    margin-top: 3%;
    margin-bottom: 1%;
}
#about-page {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: calc(65px + 8vh);
}

#feature-section {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.bio-container {
    width: calc(100% / 3 * 2);
    margin-right: 5%;
}

#self-portrait {
    max-width: 15%;
    margin-right: 5%;
}

.info-bullets {
    display: flex;
    width: 80%;
    justify-content: space-between;
    align-content: flex-start;
    margin-top: 1.5%;
    margin-bottom: 5%;
    grid-column-gap: 5%;
    -webkit-column-gap: 5%;
            column-gap: 5%;
    float: right;
}

.info-bullet-group {
    width: 35%;
}

.attribution {
    padding-left: 0;
    margin-top: 1%;
}

.edu-para {
    margin-bottom: 10%;
}

li {
    margin-bottom: 1em;
}

ul {
    list-style-type: '*';
    /* list-style-position: outside; */
  }

.xp {
    font-weight: 700;
}

  .Underlined-Link:hover {
    -webkit-text-decoration:underline 1px solid;
            text-decoration:underline 1px solid;
    -webkit-text-decoration-style: wavy;
            text-decoration-style: wavy;
    text-underline-offset: 5px;
    transform: scale(1.1);
    transition: all .2s ease-in-out;
  }

  /* media queries small */

  @media only screen and (max-width: 779px) {
    #feature-section {
        flex-direction: column;
        align-items: center;

    }

    #self-portrait {
        max-width: 150px;
        margin-left: auto;
        margin-right: auto;
    }

    .bio-container {
        width: 100%;
        margin-right: 0;
    }

    .info-bullets {
        flex-direction: column;
        float:none;
    }

    .info-bullet-group {
        width: 100%;
    }

    #contact-cta {
        margin-right: 0;
        margin-bottom: 3vh;
    }
  }

  /* media queries med*/

@media only screen and (min-width: 780px) and (max-width: 1279px) {
    #self-portrait {
        max-width: 175px;
        margin-right: 5%;
    }
}
.page-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.projectGrid {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1%;
    margin-bottom: 5%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
    -webkit-filter: drop-shadow(0 2mm 2mm #515d6346);
            filter: drop-shadow(0 2mm 2mm #515d6346);
    height: auto;
}

.hero-image {
    width: 100%;
    min-width: 400px;
    height: calc(80vw / 2 / 4 * 3);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left top;
}

.hero-image:hover {
    box-shadow: inset 0 0 0 1000px rgba(92, 83, 71, 0.2);
    /* transition: 0.3s; */
}

.project-label {
    text-decoration: none;
}

.projectLink {
    text-decoration: none;
}

.project-hitbox:hover {
    -webkit-text-decoration: 2px solid underline;
            text-decoration: 2px solid underline;
    -webkit-text-decoration-style: wavy;
            text-decoration-style: wavy;
    -webkit-text-decoration-color: #382F2F;
            text-decoration-color: #382F2F;
    text-underline-offset: 8px;
    /* box-shadow: inset 0 0 0 1000px rgba(92, 83, 71, 0.2); */
    transition: 0.3s;
}

.title {
    /* opacity: 0; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

#rm {
    background-image: url(/static/media/rm-logo.985dbb70.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

#ib {
    background-image: url(/static/media/ib-hero.a65ad58e.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left top;
}

#displace {
    background-image: url(/static/media/dis1.cb2ffbff.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

#acDf {
    background-image: url(/static/media/dreamy-finder.361b8dc2.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left center;
}

#emoji {
    background-image: url(/static/media/emojitranslate.9bbbc04a.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right top;
}

#hints {
    background-image: url(/static/media/hints-hero.5cab0b64.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

#iil-booking {
    background-image: url(/static/media/iil-booking.bb189c46.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

#screen {
    background-image: url(/static/media/screen-sector-hero.254982af.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

#banking {
    background-image: url(/static/media/banking-hero.7b54f9ca.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

#sc-review {
    background-image: url(/static/media/sc-process.8e0bac5b.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

#pec {
    background-image: url(/static/media/pec-desk.2373319f.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

@media only screen and (max-width: 780px) {
    .projectGrid {
        display: flex;
        flex-direction: column;
        grid-row-gap: 3vh;
        row-gap: 3vh;
    }
    .hero-image {
        height: calc(80vw / 4 * 3);
    } 
}

@media only screen and (max-width: 1040px) {
    .hero-image {
        min-width: 256px;
    }
}

@media only screen and (min-width: 1550px) {
    .projectGrid {
        grid-template-columns: 1fr 1fr 1fr; 
    }
    .hero-image {
        height: calc(80vw / 3 / 4 * 3);
    } 
}

/* media queries xl */
@media only screen and (min-width: 3500px) {
    .projectGrid {
        grid-gap: 60px;
    }
}
.work-feature {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3%;
}

.feature-section {
    display: flex;
    justify-content: left;
}

.project-two-thirds {
    width: calc(100% / 3 * 2);
    -webkit-filter: drop-shadow(0 2mm 2mm #515d6346);
            filter: drop-shadow(0 2mm 2mm #515d6346);
}

.project-half {
    width: calc(100% / 3 * 2);
    -webkit-filter: drop-shadow(0 2mm 2mm #515d6346);
            filter: drop-shadow(0 2mm 2mm #515d6346);
}

#test-image {
    width: calc(80vw / 3 * 2);
    margin-bottom: 5px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left top;
}

#iil-booking {
        height: calc(80vw / 3 * 2 / 4 * 3);
}

#displace {
    height: calc(80vw / 2 / 4* 3);
}

#test-image1 {
    width: calc(80vw / 3 * 2);
    height: auto;
    margin-bottom: 5px;
}

.project-label {
    display: flex;
    justify-content: space-between;
}

.project-label-large {
    margin-top: 10px;
}

.project-label-sml {
    margin-top: 10px;
}

#light-text {
    font-weight: 300;
}

/* media queries */

@media only screen and (max-width: 780px) {
    .feature-section {
        flex-direction: column;
        margin-bottom: 3vh;
    }

    .project-label-large {
        margin-bottom: 0;
    }

    .text-block-third {
        width: 100%;
    }

    .project-two-thirds {
        width: 100%;
    }

    #test-image {
        width: 100%;
    }

    #test-image1 {
        width: 100%;
        margin-bottom: 5%;
    }

    #iil-booking {
        height: calc(80vw / 4* 3);
    }

}

/* media queries small*/

@media only screen and (max-width: 480px) {
    

}
@font-face {
    font-family: 'HK Grotesk Bold';
    src: local('HK Grotesk'), url(/static/media/HKGrotesk-Bold.29f56d9e.woff) format('woff');
}

@font-face {
    font-family: 'HK Grotesk SemiBold';
    src: local('HK Grotesk'), url(/static/media/HKGrotesk-SemiBold.75f2b19b.woff) format('woff');
}

#notfound-page {
    margin-top: calc(65px + 8vh);
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
}

#elle {
    max-width: 40%;
}

.content404 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 5%;
    padding-bottom: 5%;
    margin-bottom: calc(65px + 4vh);
    background-color: #d49b9b;
    -webkit-filter: drop-shadow(0 5mm 5mm #a1a1a148);
            filter: drop-shadow(0 5mm 5mm #a1a1a148);
}

.text404 {
    max-width: 40%;
    text-align: center;
}

.notfoundh1 {
    font-family: 'HK Grotesk Bold';
    font-size: 80px;
    color: #ffebeb;
    margin-bottom: 2%;
    margin-top: 0;
}

.notfoundh2 {
    font-family: 'HK Grotesk SemiBold';
    font-size: 45px;
    color: #ffebeb;
    margin-bottom: 4%;
}

.notfoundh3 {
    font-family: 'HK Grotesk SemiBold';
    font-size: 20px;
    color: #ffebeb;
    margin-top: 0;
}

@media only screen and (max-width: 700px) {
    .content404 {
        flex-direction: column-reverse;
    }

    #elle {
        max-width: 90%;
    }

    .text404 {
        max-width: 90%;
        margin-top: 5%;
    }
}
@font-face {
    font-family: 'HK Grotesk SemiBold';
    src: local('HK Grotesk'), url(/static/media/HKGrotesk-SemiBold.75f2b19b.woff) format('woff');
}

@font-face {
    font-family: 'HK Grotesk Bold';
    src: local('HK Grotesk'), url(/static/media/HKGrotesk-Bold.29f56d9e.woff) format('woff');
}

@font-face {
    font-family: 'HK Grotesk';
    src: local('HK Grotesk'), url(/static/media/HKGrotesk-Regular.a22f9227.woff) format('woff');
}

@font-face {
    font-family: 'HK Grotesk Bold';
    src: local('HK Grotesk'), url(/static/media/HKGrotesk-Black.aebf1a9c.woff) format('woff');
}

.rm-page {
    background-color: #f2f2f2c2;
    margin-left: auto;
    margin-right: auto;
    padding: 4.5%;
    margin-top: calc(65px + 8vh);
    -webkit-filter: drop-shadow(0 2mm 2mm #3b3b3b3f);
            filter: drop-shadow(0 2mm 2mm #3b3b3b3f);
}

.rm-challenge {
    background-color: #ffffff;
    margin-top: 0;
    padding-top: 2%;
    padding-bottom: 2%;
}

.rm-concept {
    background-color: #CDD7DF;
    margin-top: 0;
    padding-top: 2%;
    padding-bottom: 2%;
}

.rm-prototype {
    background-color: #f2f2f2;
    margin-top: 0;
    padding-top: 2%;
    padding-bottom: 2%;
}

.rm-finishing {
    background-color: #ffffff;
    margin-top: 0;
    padding-top: 2%;
    padding-bottom: 2%;
}

.rm-future {
    background-color: #576D80;
    margin-top: 0;
    padding-top: 2%;
    padding-bottom: 5%;
}

.hardware {
    display: flex;
    justify-content: space-around;
    background-color: #f2f2f2;
    margin-top: 2%;
    margin-bottom: 2%;
}

.hardware-list {
    max-width: 66%;
    padding-left: 2%;
    padding-right: 2%;
}

.pi-process-container {
    max-width: 100%;
    background-color: #f2f2f2;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-row-gap: 15px;
    row-gap: 15px;
    align-items: top;
    justify-content: center;
    justify-items: center;
    align-content: center;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 0.5%;
    padding-right: 0.5%;
}

.mat-process-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-row-gap: 15px;
    row-gap: 15px;
    align-items: top;
    justify-content: center;
    justify-items: center;
    align-content: center;
    background-color: #f2f2f2;
    padding-top: 15px;
    padding-bottom: 15px;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
    margin-bottom: 5%;
}

.app-process-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-row-gap: 15px;
    row-gap: 15px;
    align-items: top;
    justify-content: center;
    justify-items: center;
    align-content: center;
    background-color: #f2f2f2;
    padding-top: 2%;
    padding-bottom: 2%;
    grid-row-gap: 15px;
    row-gap: 15px;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
}

.rm-reqs-cont {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    padding-top: 2%;
    padding-bottom: 2%;
    margin-top: 5%;
}

.rm-reqs {
    max-width: 50%;
    margin-right: auto;
    margin-left: auto;

}

/* HEADINGS */

#rm-heading {
    color: #7d8f9b;
    max-width: 70%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5%;
}

#rm-subtitle {
    color: #7d8f9b;
    font-family: 'HK Grotesk SemiBold', sans-serif;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 0;
    margin-bottom: 4%;
    max-width: 90%;
    font-size: 18px;
}

.rm-h2 {
    font-family: 'HK Grotesk SemiBold', sans-serif;
    font-size: 28px;
    color: #7d8f9b;
    margin-top: 3%;
    margin-bottom: 1%;
}

.rm-h2-alt {
    font-family: 'HK Grotesk SemiBold', sans-serif;
    font-size: 28px;
    color: #CDD7DF;
    margin-top: 3%;
    margin-bottom: 1%;
}

.rm-page h4 {
    font-family: 'HK Grotesk SemiBold', sans-serif;
    font-size: 20px;
    color: #3b3b3b;
    margin-bottom: 5%;
}

/*TEXT*/

.rm-page p {
    font-family: 'HK Grotesk', sans-serif;
    font-size: 18px;
    color: #3B3B3B;
    margin-top: 0;
}

.rm-written-content {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

#rm-hmw {
    max-width: 100%;
    background-color: #f2f2f2;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-family: 'HK Grotesk Bold', sans-serif;
}


.rm-h3 {
    font-family: 'HK Grotesk SemiBold', sans-serif;
    font-size: 36px;
    color: #3B3B3B;
    padding-left: 1.5%;
    padding-right: 1.5%;
    padding-top: 3%;
    padding-bottom: 3%;
    max-width: 70vw;
    margin-left: auto;
    margin-right: auto;
}

.proto-h3 {
    font-family: 'HK Grotesk SemiBold', sans-serif;
    font-size: 36px;
    color: #7d8f9b;
    max-width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding-top: 3%;
}

/*IMAGES*/

#mat-concept {
    width: 55%;
}

.app-concept {
    max-width: 170px;
}

.app-concepts {
    max-width: 30%;
}

.rm-concept-cont {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
}

.proto-process {
    max-width: 50%;
}

.pi-process {
    max-width: 90%;
    margin-left: 0.5%;
    margin-right: 0.5%;
}

#rm-hardware {
    max-width: 600px;
    width: 100%;
}

.app-process {
    max-width: 80%;
}

.rm-video-sec {
    width: 80%;
    background-color: #f2f2f2;
    padding-bottom: 3%;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 768px) {
    .rm-concept-cont {
        flex-direction: column;
    }

    #mat-concept {
        width: 100%;
    }

    .rm-reqs-cont {
        flex-direction: column;
    }

    .app-concepts {
        display: flex;
        justify-content: center;
        max-width: 80%;
        margin-right: auto;
        margin-left: auto;
    }

    .rm-reqs {
        max-width: 80%;
    }

    .rm-video-sec {
        width: 100%;
        background-color: #f2f2f2;
        padding-bottom: 3%;
    }

}

@media only screen and (max-width: 1350px) {
    .hardware {
        flex-direction: column;
        align-items: center;
    }
}
.next-project-bar {
    display: flex;
    justify-content: space-between;
    width: 80vw;
}

.med-link {
    font-family: "Manrope", sans-serif;
    font-weight: 700;
    font-size: clamp(1rem, 1vw, 1.5rem);
    color: #382F2F;
    text-decoration: none;
    text-underline-offset: 10px;
  }

  .med-link:hover {
    -webkit-text-decoration: 1.5px underline;
            text-decoration: 1.5px underline;
    -webkit-text-decoration-style: wavy;
            text-decoration-style: wavy;
  }

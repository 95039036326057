@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

@font-face {
  font-family: 'Fraunces';
  font-display: swap;
  font-weight: 100 500 600 700 900;
  src: url(./Fonts/Fraunces--latin_basic.woff2) format('woff2');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

@font-face {
  font-family: 'Fraunces';
  font-display: swap;
  font-weight: 100 700 900;
  src: url(./Fonts/Fraunces--latin_ext.woff2) format('woff2');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

.App {
  margin-top:0;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  padding-bottom: 5%;
  position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.dropdownContent {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.2s ease-in;
  transition: max-height 0.2s ease-in;
  margin-bottom: 5%;
}

.dropdownContainer:hover > .dropdownContent {
  max-height: 500px;
}

.swiper-container {
  background-color: #FFFFFF;
}

.popup-content {
  background:#FFFFFF !important;
}

.visibleContainer {
  max-height:300px;
}

/* typography */

body {
  background-color: #F2E5D9;
  font-size: 16px;
}

h1 {
  font-family: "Fraunces", serif;
  font-optical-sizing: auto;
  font-weight: 680;
  font-style: normal;
  font-variation-settings:
    "SOFT" 100,
    "WONK" 0.5;
  color: #5C5347;
  font-size: clamp(2rem, 3.25vw, 9rem);
  margin-top: 0;
  margin-bottom: 3%;
}

h2 {
  margin-block-start: 0;
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  font-size: clamp(1.25rem, 1.15vw, 2.25rem);
  color: #382F2F;
  text-decoration: none;
  margin-bottom: 2.5%;
}

h2.contact {
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  font-size: clamp(0.8rem, 1.25vw, 1.5rem);
  color: #382F2F;
  text-transform: uppercase;
}

p {
  font-family: "Manrope", sans-serif;
  font-weight: 500;
  font-size: clamp(1rem, 1vw, 1.75rem);
  color: #382F2F;
}

.p-feature {
  font-family: "Fraunces", serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-variation-settings:
    "SOFT" 100,
    "WONK" 0.5;
  color: #5C5347;
  font-size: clamp(1.75rem, 3.5vw, 6.5rem);
}

.p-medium {
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  font-size: clamp(1rem, 1.25vw, 1.5rem);
  color: #382F2F;
  text-decoration: none;
  margin-bottom: 5%;
}

.p-attribution {
  font-family: "Manrope", sans-serif;
    font-weight: 400;
    font-size: clamp(0.8rem, 0.6vw, 1.25rem);
    color: #382F2F;
    text-decoration: none;
}

a {
  text-decoration:underline 1px solid;
  text-underline-offset: 5px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: clamp(0.9rem, 1vw, 2rem);
  color: #382F2F;
}

.large-link:has(button) {
  text-decoration: none;
}

a:hover {
  transform: scale(1.1);
  transition: all 0.33s ease-in-out;
  text-decoration-style: wavy;
}

.large-link {
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: clamp(1.25rem, 1vw, 2rem);
  color: #382F2F;
  text-decoration: underline;
  text-underline-offset: 15px;
}

button {
  font-family: "Manrope", sans-serif;
    font-weight: 600;
    font-size: clamp(1rem, 1vw, 1.75rem);
    color: #382F2F;
    text-decoration: none;
    padding-top:0.5rem;
    padding-bottom:0.5rem;
    /* padding-left: 3rem;
    padding-right: 3rem; */
    border: 1.5px solid #382F2F;
    border-radius: 45px;
    box-shadow: 2px 2px 4px #5c534766;
    background-color: #F2EFEB;
    text-align: center;
}

ul {
	position: relative;
	list-style-type: circle;
	margin-left: 0;
	padding-left: 0.5rem;
}

li {
  font-family: "Manrope", sans-serif;
  font-weight: 500;
  font-size: clamp(1rem, 1vw, 1.75rem);
  color: #382F2F;
  text-decoration: none;  
  padding-left: 10px;
}

span {
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  font-size: clamp(1rem, 1vw, 1.75rem);
  color: #382F2F;
  text-decoration: none;
}

span.project-label-large {
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  font-size: clamp(1rem, 1.2vw, 2.25rem);
  color: #382F2F;
  text-decoration: none;
}

label {
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  font-size: clamp(1rem, 1vw, 1.5rem);
  color: #382F2F;
}

textarea {
  font-family: "Manrope", sans-serif;
  font-weight: 500;
  font-size: clamp(1rem, 1vw, 1.5rem);
}

input {
  font-family: "Manrope", sans-serif;
  font-weight: 500;
  font-size: clamp(1rem, 1vw, 1.5rem);
  color: #382F2F;
}

/* content blocks */

.full-width-content-block {
  width: 100%;
}

.text-block-third {
  width: calc(100% / 3);
  padding-right: 3%;
}

.text-block-half {
  width: calc(100% / 2);
  padding-right: 3%;
}

.half-width-content-block {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 3%;
}

.video-section {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 80vw;
  margin-bottom: 3%;
}

/* media queries small*/

@media only screen and (max-width: 480px) {
  .half-width-content-block {
    flex-direction: column;
    row-gap: 20px;
  }
} 
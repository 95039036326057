#about-page {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: calc(65px + 8vh);
}

#feature-section {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.bio-container {
    width: calc(100% / 3 * 2);
    margin-right: 5%;
}

#self-portrait {
    max-width: 15%;
    margin-right: 5%;
}

.info-bullets {
    display: flex;
    width: 80%;
    justify-content: space-between;
    align-content: flex-start;
    margin-top: 1.5%;
    margin-bottom: 5%;
    column-gap: 5%;
    float: right;
}

.info-bullet-group {
    width: 35%;
}

.attribution {
    padding-left: 0;
    margin-top: 1%;
}

.edu-para {
    margin-bottom: 10%;
}

li {
    margin-bottom: 1em;
}

ul {
    list-style-type: '*';
    /* list-style-position: outside; */
  }

.xp {
    font-weight: 700;
}

  .Underlined-Link:hover {
    text-decoration:underline 1px solid;
    text-decoration-style: wavy;
    text-underline-offset: 5px;
    transform: scale(1.1);
    transition: all .2s ease-in-out;
  }

  /* media queries small */

  @media only screen and (max-width: 779px) {
    #feature-section {
        flex-direction: column;
        align-items: center;

    }

    #self-portrait {
        max-width: 150px;
        margin-left: auto;
        margin-right: auto;
    }

    .bio-container {
        width: 100%;
        margin-right: 0;
    }

    .info-bullets {
        flex-direction: column;
        float:none;
    }

    .info-bullet-group {
        width: 100%;
    }

    #contact-cta {
        margin-right: 0;
        margin-bottom: 3vh;
    }
  }

  /* media queries med*/

@media only screen and (min-width: 780px) and (max-width: 1279px) {
    #self-portrait {
        max-width: 175px;
        margin-right: 5%;
    }
}
#main-nav {
    margin-top: 2vh;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    height: 5vh;
    margin-bottom: 12vh;
}

#hamburger-nav {
    display: none;
}

#hamburger-list {
    display: none;
}

#nav-contents {
    display: flex;
    justify-content: space-between;
}

.nav-pages {
    display: flex;
    justify-content:right;
    width: 75%;
    align-items: center;
}

#nav-places {
    text-decoration: none;
    padding-left: 5%;
}

#nav-places:hover {
    text-decoration:underline;
    text-decoration-style: wavy;
    text-decoration-thickness: 2px;
    text-underline-offset: 5px;
}

.nav-logo {
    height: 8vh;
}

/* media query small */

@media only screen and (max-width: 480px) {
    .nav-logo {
        width: 75%;
    }

    #main-nav {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 0;
        padding-top: 15px;
    }

    #nav-contents {
        display: none;
    }

    #hamburger-nav {
        display: flex;
        justify-content: space-between;
        margin-top: 0;
    }

    #hamburger {
        fill: #5C5347;
        margin-top: 10px;
        margin-right: 20px;
    }

    .nav-logo {
        margin-left: 20px;
    }

    #hamburger-list {
        display: flex;
        flex-direction: column;
        row-gap: 5rem;
        width: 100%;
        height: 97vh;
        position: fixed;
        padding-top: 60px;
        z-index: 1;
        padding-left: 10vw;
        margin-top: 6rem;
        background-color: #f7f0e7f0;
            overflow-x: hidden;
            /* transition: all 30s ease-in; */
    }

    #nav-places.nav-link {
        margin-left: 0;
        padding-left: 0;
        font-size: clamp(1.125rem, 6vw, 2.5rem);
        text-decoration: 1.5px underline solid;
        text-underline-offset: 10px;
        z-index: 1;
    }
}

/* media query ipad */
/* @media only screen 
    and (min-width: 1024px)
    and (max-height: 1366px)
    {

    .nav-logo {
        max-width: 75%;
        margin-left: 5%;
    }    

    #main-nav {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 0;
        padding-top: 15px;
    }

    #nav-contents {
        display: none;
    }

    #hamburger-nav {
        display: flex;
        justify-content: space-between;
        margin-top: 0;
        max-width: 100%;
        margin-left: 0;
    }

    #hamburger {
        fill: #5C5347;
        margin-top: 10px;
        margin-right: 5%;
        width: 5%;
    }

    #hamburger-list {
        display: flex;
        flex-direction: column;
        row-gap: 5rem;
        width: 100%;
        height: 97vh;
        position: fixed;
        padding-top: 60px;
        z-index: 1;
        padding-left: 10vw;
        margin-top: 6rem;
        background-color: #f7f0e7f0;
        overflow-x: hidden;
    }

    #nav-places.large-link {
        margin-left: 0;
        padding-left: 0;
        text-decoration: 1px underline solid;
        text-underline-offset: 10px;
        z-index: 1;
    }
    
    rect {
        width: 100%;
    }

} */
.contactFooter {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    margin-top: 0;
    margin-bottom: 5%;
    padding-bottom: 50px;
}

.footer-hr {
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
    border: 0;
    height: 0;
    border: 1px solid #5C5347;
}

.contact-blurb {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.5%;
}

#contact-textblock {
    width: calc(100% / 3 * 2);
    padding-right: 5%;
}

.contact {
    text-transform: uppercase;
    margin-bottom: 10px;
}

#footer-text {
    margin-top: 10px;
    margin-bottom: 10px;
}

.button-reg {
    padding-left: 3vw;
    padding-right: 3vw;
}

button.button-reg:hover {
    transform: scale(1.05);
    background-color: #5C5347;
    color: white;
    transition: all .2s ease-in-out;
    font-weight: 600;
}

.contact-links {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 5%;
    margin-top: 2.5%;
    margin-right: 2.5%;
}

.contactLink {
    padding-right: 2.5%;
    text-decoration: underline 1px solid;
    margin-bottom: 15%;
    text-underline-offset: 5px;
}

.contactLink:hover {
    text-decoration: underline 2px;
    text-decoration-style: wavy;
    text-underline-offset: 5px;
    transition: all .2s ease-in-out;
}


/* wave footer svg version */

.wavefooter svg {
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    width: calc(100% + 1.3px);
    height: 10vh;
    transform: rotate(180deg);
}

.wavefooter .shape-fill {
    fill: #5C5347;
}
.next-project-bar {
    display: flex;
    justify-content: space-between;
    width: 80vw;
}

.med-link {
    font-family: "Manrope", sans-serif;
    font-weight: 700;
    font-size: clamp(1rem, 1vw, 1.5rem);
    color: #382F2F;
    text-decoration: none;
    text-underline-offset: 10px;
  }

  .med-link:hover {
    text-decoration: 1.5px underline;
    text-decoration-style: wavy;
  }
.page-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.projectGrid {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1%;
    margin-bottom: 5%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
    filter: drop-shadow(0 2mm 2mm #515d6346);
    height: auto;
}

.hero-image {
    width: 100%;
    min-width: 400px;
    height: calc(80vw / 2 / 4 * 3);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left top;
}

.hero-image:hover {
    box-shadow: inset 0 0 0 1000px rgba(92, 83, 71, 0.2);
    /* transition: 0.3s; */
}

.project-label {
    text-decoration: none;
}

.projectLink {
    text-decoration: none;
}

.project-hitbox:hover {
    text-decoration: 2px solid underline;
    text-decoration-style: wavy;
    text-decoration-color: #382F2F;
    text-underline-offset: 8px;
    /* box-shadow: inset 0 0 0 1000px rgba(92, 83, 71, 0.2); */
    transition: 0.3s;
}

.title {
    /* opacity: 0; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

#rm {
    background-image: url('../../Assets/rm-logo.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

#ib {
    background-image: url('../../Assets/ib-hero.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left top;
}

#displace {
    background-image: url('../../Assets/dis1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

#acDf {
    background-image: url('../../Assets/dreamy-finder.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left center;
}

#emoji {
    background-image: url('../../Assets/emojitranslate.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right top;
}

#hints {
    background-image: url('../../Assets/hints-hero.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

#iil-booking {
    background-image: url('../../Assets/iil-booking.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

#screen {
    background-image: url('../../Assets/screen-sector-hero.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

#banking {
    background-image: url('../../Assets/banking-hero.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

#sc-review {
    background-image: url('../../Assets/sc-process.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

#pec {
    background-image: url('../../Assets/pec/pec-desk.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

@media only screen and (max-width: 780px) {
    .projectGrid {
        display: flex;
        flex-direction: column;
        row-gap: 3vh;
    }
    .hero-image {
        height: calc(80vw / 4 * 3);
    } 
}

@media only screen and (max-width: 1040px) {
    .hero-image {
        min-width: 256px;
    }
}

@media only screen and (min-width: 1550px) {
    .projectGrid {
        grid-template-columns: 1fr 1fr 1fr; 
    }
    .hero-image {
        height: calc(80vw / 3 / 4 * 3);
    } 
}

/* media queries xl */
@media only screen and (min-width: 3500px) {
    .projectGrid {
        grid-gap: 60px;
    }
}
@font-face {
    font-family: 'HK Grotesk SemiBold';
    src: local('HK Grotesk'), url(../../Fonts/HKGrotesk-SemiBold.woff) format('woff');
}

@font-face {
    font-family: 'HK Grotesk Bold';
    src: local('HK Grotesk'), url(../../Fonts/HKGrotesk-Bold.woff) format('woff');
}

@font-face {
    font-family: 'HK Grotesk';
    src: local('HK Grotesk'), url(../../Fonts/HKGrotesk-Regular.woff) format('woff');
}

@font-face {
    font-family: 'HK Grotesk Bold';
    src: local('HK Grotesk'), url(../../Fonts/HKGrotesk-Black.woff) format('woff');
}

.rm-page {
    background-color: #f2f2f2c2;
    margin-left: auto;
    margin-right: auto;
    padding: 4.5%;
    margin-top: calc(65px + 8vh);
    filter: drop-shadow(0 2mm 2mm #3b3b3b3f);
}

.rm-challenge {
    background-color: #ffffff;
    margin-top: 0;
    padding-top: 2%;
    padding-bottom: 2%;
}

.rm-concept {
    background-color: #CDD7DF;
    margin-top: 0;
    padding-top: 2%;
    padding-bottom: 2%;
}

.rm-prototype {
    background-color: #f2f2f2;
    margin-top: 0;
    padding-top: 2%;
    padding-bottom: 2%;
}

.rm-finishing {
    background-color: #ffffff;
    margin-top: 0;
    padding-top: 2%;
    padding-bottom: 2%;
}

.rm-future {
    background-color: #576D80;
    margin-top: 0;
    padding-top: 2%;
    padding-bottom: 5%;
}

.hardware {
    display: flex;
    justify-content: space-around;
    background-color: #f2f2f2;
    margin-top: 2%;
    margin-bottom: 2%;
}

.hardware-list {
    max-width: 66%;
    padding-left: 2%;
    padding-right: 2%;
}

.pi-process-container {
    max-width: 100%;
    background-color: #f2f2f2;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    row-gap: 15px;
    align-items: top;
    justify-content: center;
    justify-items: center;
    align-content: center;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 0.5%;
    padding-right: 0.5%;
}

.mat-process-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    row-gap: 15px;
    align-items: top;
    justify-content: center;
    justify-items: center;
    align-content: center;
    background-color: #f2f2f2;
    padding-top: 15px;
    padding-bottom: 15px;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
    margin-bottom: 5%;
}

.app-process-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    row-gap: 15px;
    align-items: top;
    justify-content: center;
    justify-items: center;
    align-content: center;
    background-color: #f2f2f2;
    padding-top: 2%;
    padding-bottom: 2%;
    row-gap: 15px;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
}

.rm-reqs-cont {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    padding-top: 2%;
    padding-bottom: 2%;
    margin-top: 5%;
}

.rm-reqs {
    max-width: 50%;
    margin-right: auto;
    margin-left: auto;

}

/* HEADINGS */

#rm-heading {
    color: #7d8f9b;
    max-width: 70%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5%;
}

#rm-subtitle {
    color: #7d8f9b;
    font-family: 'HK Grotesk SemiBold', sans-serif;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 0;
    margin-bottom: 4%;
    max-width: 90%;
    font-size: 18px;
}

.rm-h2 {
    font-family: 'HK Grotesk SemiBold', sans-serif;
    font-size: 28px;
    color: #7d8f9b;
    margin-top: 3%;
    margin-bottom: 1%;
}

.rm-h2-alt {
    font-family: 'HK Grotesk SemiBold', sans-serif;
    font-size: 28px;
    color: #CDD7DF;
    margin-top: 3%;
    margin-bottom: 1%;
}

.rm-page h4 {
    font-family: 'HK Grotesk SemiBold', sans-serif;
    font-size: 20px;
    color: #3b3b3b;
    margin-bottom: 5%;
}

/*TEXT*/

.rm-page p {
    font-family: 'HK Grotesk', sans-serif;
    font-size: 18px;
    color: #3B3B3B;
    margin-top: 0;
}

.rm-written-content {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

#rm-hmw {
    max-width: 100%;
    background-color: #f2f2f2;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-family: 'HK Grotesk Bold', sans-serif;
}


.rm-h3 {
    font-family: 'HK Grotesk SemiBold', sans-serif;
    font-size: 36px;
    color: #3B3B3B;
    padding-left: 1.5%;
    padding-right: 1.5%;
    padding-top: 3%;
    padding-bottom: 3%;
    max-width: 70vw;
    margin-left: auto;
    margin-right: auto;
}

.proto-h3 {
    font-family: 'HK Grotesk SemiBold', sans-serif;
    font-size: 36px;
    color: #7d8f9b;
    max-width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding-top: 3%;
}

/*IMAGES*/

#mat-concept {
    width: 55%;
}

.app-concept {
    max-width: 170px;
}

.app-concepts {
    max-width: 30%;
}

.rm-concept-cont {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
}

.proto-process {
    max-width: 50%;
}

.pi-process {
    max-width: 90%;
    margin-left: 0.5%;
    margin-right: 0.5%;
}

#rm-hardware {
    max-width: 600px;
    width: 100%;
}

.app-process {
    max-width: 80%;
}

.rm-video-sec {
    width: 80%;
    background-color: #f2f2f2;
    padding-bottom: 3%;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 768px) {
    .rm-concept-cont {
        flex-direction: column;
    }

    #mat-concept {
        width: 100%;
    }

    .rm-reqs-cont {
        flex-direction: column;
    }

    .app-concepts {
        display: flex;
        justify-content: center;
        max-width: 80%;
        margin-right: auto;
        margin-left: auto;
    }

    .rm-reqs {
        max-width: 80%;
    }

    .rm-video-sec {
        width: 100%;
        background-color: #f2f2f2;
        padding-bottom: 3%;
    }

}

@media only screen and (max-width: 1350px) {
    .hardware {
        flex-direction: column;
        align-items: center;
    }
}
.work-feature {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3%;
}

.feature-section {
    display: flex;
    justify-content: left;
}

.project-two-thirds {
    width: calc(100% / 3 * 2);
    filter: drop-shadow(0 2mm 2mm #515d6346);
}

.project-half {
    width: calc(100% / 3 * 2);
    filter: drop-shadow(0 2mm 2mm #515d6346);
}

#test-image {
    width: calc(80vw / 3 * 2);
    margin-bottom: 5px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left top;
}

#iil-booking {
        height: calc(80vw / 3 * 2 / 4 * 3);
}

#displace {
    height: calc(80vw / 2 / 4* 3);
}

#test-image1 {
    width: calc(80vw / 3 * 2);
    height: auto;
    margin-bottom: 5px;
}

.project-label {
    display: flex;
    justify-content: space-between;
}

.project-label-large {
    margin-top: 10px;
}

.project-label-sml {
    margin-top: 10px;
}

#light-text {
    font-weight: 300;
}

/* media queries */

@media only screen and (max-width: 780px) {
    .feature-section {
        flex-direction: column;
        margin-bottom: 3vh;
    }

    .project-label-large {
        margin-bottom: 0;
    }

    .text-block-third {
        width: 100%;
    }

    .project-two-thirds {
        width: 100%;
    }

    #test-image {
        width: 100%;
    }

    #test-image1 {
        width: 100%;
        margin-bottom: 5%;
    }

    #iil-booking {
        height: calc(80vw / 4* 3);
    }

}

/* media queries small*/

@media only screen and (max-width: 480px) {
    

}
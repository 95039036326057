.project-page {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
}

.project-heading-container {
   display: flex;
   justify-content: flex-start; 
   margin-bottom: 0;
}

.long-project-title {
    margin-top: 2.5%;
}

.type-of-work {
    font-weight: 800;
    color: #5C5347;
    margin-left: 0;
    margin-right: 0;
    margin-top: 5%;
    margin-bottom: 5%;
}

.external-link {
    font-family: "Manrope", sans-serif;
    font-weight: 800;
    color: #382F2F;
    margin-bottom: 15%;
    text-decoration:underline;
    text-underline-offset: 5px;
}

.hero-two-thirds {
    margin-top: 3%;
    width: 70%;
}

.body-copy {
    width: calc(80vw / 3 * 2);
    float: right;
    margin-top: 0;
    margin-bottom: 3%;
}

.full-width-test {
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    margin-bottom: 3%;
}

#half-width-test {
    width: 48.5%;
}

.hero-image-w {
    width: 80vw;
    height: 82vh;
}

.main-project-image {
    max-width: 60%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5vh;
    margin-bottom: 5vh;
}

.final-images {
    width: 29vw;
    height: 35vh;
    margin-bottom: 3%;
}

.finalImages {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 5px;
}

.photoSeriesImgs {
    width: 13vw;
    height: 7vh;
}

.photoSeries {
    max-width: 60%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    column-gap: 0;
}

.infographic {
    max-width: 100vw;
    height: 200vh;
}

.content{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.process {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.processVid {
    max-width: 100%;
}

.processVids {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
}

.video-section {
    width: 100%;
    border-radius: 10px;
}

.vid {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    border-radius: 20px;
    filter: drop-shadow(0 2mm 2mm #515d6346);
}

.center-img {
    display: flex;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 5%;
}

.hint-flows {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.hint-dp {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-top: 0;
    align-content: center;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-bottom: 5%;
}

.hint-sketches {
    max-height: 45vh;
}

.hint-sketches2 {
    max-height: 60vh;
    justify-self: starts;
}

#displace1 {
    background-image: url('../../Assets/dis1.jpg');
    background-size: contain;
    margin-bottom: 3vh;
    background-repeat: no-repeat;
}
#displace2 {
    background-image: url('../../Assets/dis2.jpg');
    background-size: contain;
    margin-bottom: 3vh;
    background-repeat: no-repeat;
}
#displace3 {
    background-image: url('../../Assets/dis3.jpg');
    background-size: contain;
    margin-bottom: 3vh;
    background-repeat: no-repeat;
}
#displace4 {
    background-image: url('../../Assets/dis4.jpg');
    background-size: contain;
    margin-bottom: 3vh;
    background-repeat: no-repeat;
}
#displace5 {
    background-image: url('../../Assets/dis5.jpg');
    background-size: contain;
    margin-bottom: 3vh;
    background-repeat: no-repeat;
}

#displace6 {
    background-image: url('../../Assets/dis6.png');
    background-size: contain;
    background-repeat: no-repeat;
}

.disGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-self: center;
    align-self: center;
}

#df {
    background-image: url('../../Assets/dreamy-finder.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center left;
}

#bus {
    background-image: url('../../Assets/bus-images/bus-hero.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

/* media queries small */

@media only screen and (max-width: 480px) {
    .project-heading-container {
        flex-direction: column;
        margin-bottom: 0;
    }

    .project-heading-container :nth-child(2) {
        order: -1; 
    }

    #half-width-test {
        width: 100%;
    }

    .full-width-test {
        margin-bottom: 20px;
    }

    .hero-two-thirds {
        width: 100%;
    }

    .long-project-title {
        margin-top: 2.5%;
    }

    .body-copy {
        width: 100%;
    }
}
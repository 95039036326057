.intro {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

#intro-text-container {
    width: 66%;
    height: 30%;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    margin-top: auto;
    margin-bottom: auto;
}

.large-link:hover {
    text-decoration:underline;
    text-decoration-style: wavy;
    transition: all .2s ease-in-out;
}

#home-wave {
    width: 100%;
    rotate: 180deg;
    position: fixed;
    left: 0;
    bottom: 0;
}

#home-wave .shape-fill {
    fill: #5C5347;
}

/* #wave1 {
    height: 10vh;
} */

/* media queries small */

@media only screen and (max-width: 480px) {
    #intro-text-container {
        width: 100%;
    }

    #wave1 {
        height: 10vh;
        width: 100%;
    }
}


.contact-form {
    display: flex;
    flex-direction: column;
    width: calc(100vw / 2);
    /* margin-top: 40px; */
    margin-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0;
    padding-top: 5vh;
    background-color: rgba(255, 255, 255, 0.53);
    border-radius: 10px;
    margin-left: 5%;
}

#contact-cta {
    color: #382F2F;
    margin-right: 20%;
    margin-bottom: 0;
}

input {
    padding: 10px;
    margin-bottom: 40px;
    margin-top: 15px;
    border-radius: 10px;
    border: 1.5px solid #382f2f74;
}

label {
    display: flex;
    flex-direction: column;
}

.fields {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
}

textarea {
    margin-bottom: 40px;
    margin-top: 15px;
    padding: 10px;
    border-radius: 10px;
    border: 1.5px solid #382f2f74;
    height: 300px;
}

.contact-heading {
    margin-bottom: 5%;
}

.button-wide {
    margin-bottom: 20%;
    display: block;
    float: right;
    text-decoration: none;
    width: calc(100%/ 3);
}

#email-wave {
    width: 100%;
    rotate: 180deg;
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 10vh;
}

#email-wave .shape-fill {
    fill: #5C5347;
}

#returntowork {
    margin-top: 7.5%;
}

/* media queries */

@media only screen and (max-width: 779px) {
    .contact-form {
        width: 80vw;
        margin-left: auto;
        margin-right: auto;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        padding-top: 2.5vh;
    }

    #success-msg {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    #returntowork {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        margin-top: 15%;
    }

    .large-link.c-success {
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
}

/* media queries xl */
@media only screen and (min-width: 3500px) {

    label {
        row-gap: 10px;
    }

    input {
        margin-bottom: 65px;
    }

    textarea {
        margin-bottom: 65px;
    }
}